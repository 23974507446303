<template>
  <div
    class="uk-flex uk-flex-center uk-flex-middle uk-background-cover"
    uk-height-viewport
    v-bind:style="{
      backgroundImage:
        'url(' +
        api +
        '/storefronts/' +
        storefrontId +
        '/background-login' +
        ')',
    }"
  >
    <div>
      <div
        class="uk-card rounded-2xl shadow-xl border border-gray-400 uk-width-medium uk-padding"
        :style="
          'background-color:' +
          storefrontStorefront.login_color_box +
          '!important'
        "
      >
        <div class="uk-text-center">
          <img :src="api + '/storefronts/' + storefrontId + '/logoLogin'" alt />
        </div>

        <ValidationObserver v-slot="{ invalid }">
          <ValidationProvider name="email" rules="required|email">
            <input
              test="email-input"
              class="uk-margin-top uk-input uk-text-center uk-border-rounded"
              type="text"
              v-model="model.email"
              placeholder="correo"
              name="email"
              @keydown="keydownHandler"
            />
          </ValidationProvider>

          <ValidationProvider name="password" rules="required">
            <input
              test="password-input"
              class="uk-margin-top uk-input uk-text-center uk-border-rounded"
              type="password"
              v-model="model.password"
              placeholder="contraseña"
              name="password"
              @keydown="keydownHandler"
            />
          </ValidationProvider>

          <div class="uk-text-center uk-margin-top">
            <button
              test="login-button"
              class="w-full bg-gray-200 px-2 py-2 rounded-2xl text-smk-button uk-button-primary uk-border-rounded"
              @click="onClickRenderCaptcha()"
              :disabled="invalid"
            >
              Ingresar
            </button>

            <div id="pets-container"></div>
            <div id="error-container"></div>

            <div class="flex justify-center mt-4">
              <div class="bg-white p-2 rounded-full shadow-xl">
                <a
                  :href="
                    baseUrlBackend +
                    '/auth/google/storefont/' +
                    storefrontStorefront.id
                  "
                  title="Iniciar con Gmail"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    class="w-6 h-6"
                    viewBox="0 0 48 48"
                  >
                    <path
                      fill="#FFC107"
                      d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                    <path
                      fill="#FF3D00"
                      d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                    ></path>
                    <path
                      fill="#4CAF50"
                      d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                    ></path>
                    <path
                      fill="#1976D2"
                      d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                    ></path>
                  </svg>
                </a>
              </div>
            </div>
          </div>
        </ValidationObserver>
        <hr />
        <div class="uk-text-center uk-margin-top">
          <router-link
            to="/forgot-password"
            class="w-full hover:text-blue-400 bg-gray-200 px-2 py-2 rounded-2xl text-sm"
          >
            Olvidé mi contraseña
          </router-link>
        </div>
        <div
          class="uk-text-center uk-margin-top"
          v-if="
            !storefrontIsLoadingStorefront &&
            storefrontStorefront.public_registration
          "
        >
          <router-link
            to="/register"
            class="w-full hover:text-blue-400 bg-gray-200 px-2 py-2 rounded-2xl text-sm"
          >
            Crear nueva cuenta
          </router-link>
        </div>
      </div>
    </div>

    <div
      id="captcha-container"
      class="uk-position-center absolute bg-white p-4 border border-gray-300 p-4 rounded-2xl shadow-xl w-auto hidden"
    ></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AuthLogin",

  data() {
    return {
      model: {
        email: "",
        password: "",
        token: null,
        user_id: null,
      },
      backgrounds: [],
    };
  },

  computed: {
    ...mapGetters([
      "authIsLogingIn",
      "storefrontStorefront",
      "storefrontIsLoadingStorefront",
      "authLoginFailedattemps",
    ]),
  },

  mounted() {
    if (window.location.search.includes("token=")) {
      this.model.token = new URLSearchParams(window.location.search).get(
        "token"
      );
      this.user_id = new URLSearchParams(window.location.search).get("user_id");

      this.login(this.model);
    }
  },

  methods: {
    ...mapActions(["login"]),
    keydownHandler(event) {
      switch (event.key) {
        case "Enter":
          event.preventDefault();
          this.login(this.model);
      }
    },

    setError(error) {
      document.getElementById("error-container").innerHTML = error.message;
      console.error(error);
    },

    onClickRenderCaptcha() {
      if (this.authLoginFailedattemps < 3) {
        this.login(this.model);
        return true;
      }

      this.renderCaptcha();
      return true;
    },

    renderCaptcha() {
      const container = document.getElementById("captcha-container");
      container.style.display = "block";
      // eslint-disable-next-line no-undef
      AwsWafCaptcha.renderCaptcha(container, {
        defaultLocale: "es",
        apiKey:
          "is56WzfQVFO4YoYSW1vTWGX+Yd5bXW2obZQja2gaE03CRDdqRgPZhi30leME/srWJlgykiTDZJIqCI2uqMjwpJLEoxgXVYcyVPCOOQgid7YLbCPV1ptqlnbRlQJaVkyCwmWZVNlXhMOKVIGJh+Qs83V1lUeyj3e6GLCN7ssFTr7PASA4iRuJqG5hrsDKkQEdfYNaGyGPrdPNVJCNtB1VIEWHSCj2HAguY7MF/PGJPDHL+O0060SPA+L3gZOQQKv9QENPFNWA2VphZZzpAVeKb4tCSf8PCmxI7QzFntN1c0VsKtR++7wkbZ/mAPjfaRDyhIi7aYrHgDVh9tdocy0Ts7OZ62SHgg9JirjuX7Qj/1D6SzruXcuGxTiFnCu4xELQU/86DyUfuA93ZQT/9/cWYY4pN3LJ4Pmw3FR8mCe2wnJfH8iQiSP9BI+fEFskuxS29hg0KGO5DGCmF0VCk/4Qke2yyBS/F5iHcavgcXQAhN5X9jGTPaQRfAHizWwJ2jLK3Ek7MVlzRNPv1bo/AImagx/hoNVehaez0CJ1Svzz03FCY5CoU0SHbJmltcO5JsIoY5gj2fZqawSRict0J8uY2YaZCY2ePzEk3NlhAZV3xjgyCDwsBHsjKZjQmnPS350Ktqy2zo0/DedDv/4LMm4fx6rvcCTTJxrHTLAWcpMibHM=_0_1",
        onSuccess: () => {
          this.login(this.model);
        },
      });
    },

    fetchProtectedData() {
      // eslint-disable-next-line no-undef
      AwsWafIntegration.fetch("./pets", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          document.getElementById("pets-container").textContent =
            JSON.stringify(data);
        })
        .catch(this.setError);
    },
  },
};
</script>
